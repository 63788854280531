<template>
    <div>
        <div class="main-title">会员卡</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">轮播图大小为：980*500</li>
                        <li data-v-69874ad0="">目前只在小程序展示</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-select v-model="value" clearable placeholder="选择分类" size="small">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l" >
                    <el-select v-model="value" clearable placeholder="选择分类" size="small">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l" >
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

<!--                <span class=" fl-r">-->
<!--                    <router-link to="/vip/add" class="router-link" >-->
<!--                        <el-button type="primary" size="small" >新增会员卡</el-button>-->
<!--                    </router-link>-->
<!--                </span>-->

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            property="name"
                            label="名称"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="类型"
                            width="200">
                        <template slot-scope="scope">
                            <span>{{scope.row.type === 1 ? '课程折扣卡' : '课程免费卡'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="折扣值" width="200">
                        <template slot-scope="scope">
                            <span>{{scope.row.discount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="状态" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 1" class="state-up"><i></i>已上架</span>
                            <span v-if="scope.row.status === 0" class="state-down"><i></i>已下架</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link v-if="scope.row.status === 1" type="primary" @click="setStatus(scope.row,scope.$index)">下架</el-link>
                                <el-link v-if="scope.row.status === 0" type="primary" @click="setStatus(scope.row,scope.$index)">上架</el-link>
                            </span> -
<!--                            <span>-->
<!--                                <el-popconfirm-->
<!--                                        icon="el-icon-info"-->
<!--                                        icon-color="red"-->
<!--                                        title="删除后将不可恢复，确定删除吗？"-->
<!--                                        @confirm="deleted(scope.row)"-->
<!--                                >-->
<!--                                  <el-link slot="reference" type="primary">删除</el-link>-->
<!--                                </el-popconfirm>-->
<!--                            </span> - -->
                            <span>
                                <el-link  type="primary" @click="vipDetail(scope.row)">详情</el-link>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "template-list",
        data(){
            return {
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                imageUrl:config.imageUrl,
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                currentRow: null
            }
        },
        methods:{
            ...mapActions('marketing',['vipCardList','setVipCardStatus','delVipCard']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            vipDetail(row){
                this.$router.push('/vip/detail/'+row.id)
                console.log(row)
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                this.vipCardList().then(res => {
                    this.tableData = res.data

                    this.loading = false
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            setStatus(row, rowIndex){
                let _this = this
                let data = {
                    id:row.id,
                    status:!row.status,
                }

                this.loading = true
                this.setVipCardStatus(data).then(success => {
                    _this.tableData[rowIndex].status = success.data.status

                    _this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.loading = false
                },error => {
                    _this.$message({
                        message: '设置失败',
                        type: 'error'
                    });
                    this.loading = false
                })
            },
            deleted(row, rowIndex){
                let _this = this
                this.loading = true
                this.delVipCard(row.id).then(success => {
                    _this.getList()
                    _this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                },error => {
                    _this.$message({
                        message: '删除失败',
                        type: 'error'
                    });
                })
            },
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>

</style>
